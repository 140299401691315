import * as React from "react";
export const SpinnerBall = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color}
      d="M15.636 2.932a12.704 12.704 0 1 0 12.705 12.704A12.719 12.719 0 0 0 15.636 2.932ZM26.364 14.96c-3.462 2.443-6.055 1.793-8.78.536 2.451-1.734 4.748-3.935 4.83-8.198a10.74 10.74 0 0 1 3.95 7.662Zm-5.95-8.951c.382 4.217-1.48 6.139-3.923 7.87-.274-2.991-1.03-6.084-4.687-8.285a10.737 10.737 0 0 1 8.612.415h-.002ZM9.69 6.686c3.841 1.777 4.577 4.346 4.85 7.33-2.727-1.258-5.784-2.15-9.52-.084a10.772 10.772 0 0 1 4.67-7.246Zm-4.782 9.627c3.462-2.443 6.056-1.793 8.78-.536-2.45 1.733-4.747 3.935-4.83 8.198a10.74 10.74 0 0 1-3.95-7.662Zm5.95 8.95c-.382-4.216 1.48-6.138 3.923-7.869.275 2.99 1.031 6.084 4.687 8.285a10.737 10.737 0 0 1-8.612-.415h.003Zm10.725-.676c-3.842-1.777-4.577-4.346-4.85-7.33 1.553.716 3.213 1.314 5.044 1.314 1.384 0 2.866-.342 4.475-1.232a10.774 10.774 0 0 1-4.669 7.248Z"
    />
  </svg>
);
export const TrendUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color}
      d="M29.199 7.509v7.75a.97.97 0 0 1-1.938 0V9.846l-9.97 9.972a.967.967 0 0 1-1.371 0l-4.159-4.16-8.033 8.035a.97.97 0 1 1-1.37-1.37l8.718-8.72a.97.97 0 0 1 1.37 0l4.16 4.16 9.286-9.287H20.48a.969.969 0 1 1 0-1.937h7.75a.969.969 0 0 1 .969.969Z"
    />
  </svg>
);

export const CoinVertical = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color}
      d="M24.795 6.852c-1.474-2.528-3.492-3.92-5.681-3.92H13.25c-2.19 0-4.207 1.392-5.682 3.92-1.378 2.361-2.136 5.486-2.136 8.784 0 3.299.758 6.424 2.136 8.785 1.475 2.527 3.493 3.92 5.682 3.92h5.864c2.189 0 4.207-1.393 5.681-3.92 1.378-2.361 2.137-5.486 2.137-8.785 0-3.298-.759-6.423-2.137-8.784Zm.157 7.807h-3.91a18.667 18.667 0 0 0-1.182-5.864h3.737c.747 1.635 1.241 3.665 1.355 5.864Zm-2.517-7.818h-3.51c-.408-.708-.9-1.364-1.466-1.955h1.655c1.221 0 2.37.733 3.321 1.955ZM7.386 15.636c0-5.827 2.688-10.75 5.864-10.75 3.176 0 5.864 4.923 5.864 10.75s-2.688 10.75-5.864 10.75c-3.176 0-5.864-4.923-5.864-10.75Zm11.728 10.75h-1.65a10.156 10.156 0 0 0 1.465-1.954h3.51c-.956 1.221-2.104 1.954-3.325 1.954Zm4.483-3.909H19.86a18.664 18.664 0 0 0 1.183-5.863h3.909c-.115 2.198-.609 4.229-1.356 5.863Z"
    />
  </svg>
);

export const CircleNotch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color}
      d="M28.371 15.636a12.705 12.705 0 1 1-25.409 0c0-5.008 2.909-9.572 7.41-11.638a.977.977 0 1 1 .816 1.776c-3.81 1.75-6.271 5.62-6.271 9.862a10.75 10.75 0 1 0 21.5 0c0-4.242-2.462-8.112-6.272-9.862a.977.977 0 0 1 .816-1.776c4.502 2.066 7.41 6.63 7.41 11.638Z"
    />
  </svg>
);

export const CaretDoubleDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color}
      d="M26.616 16.65a.978.978 0 0 1 0 1.382l-9.773 9.773a.978.978 0 0 1-1.383 0l-9.773-9.773A.978.978 0 0 1 7.07 16.65l9.081 9.083 9.082-9.082a.978.978 0 0 1 1.383 0ZM15.46 18.032a.98.98 0 0 0 1.383 0l9.773-9.772a.978.978 0 1 0-1.383-1.383l-9.082 9.082-9.08-9.082A.978.978 0 1 0 5.686 8.26l9.773 9.772Z"
    />
  </svg>
);

export const Prohibit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color}
      d="M15.667 3.66A12.705 12.705 0 1 0 28.37 16.363 12.718 12.718 0 0 0 15.667 3.659Zm10.75 12.704a10.696 10.696 0 0 1-2.494 6.875L8.791 8.106a10.75 10.75 0 0 1 17.626 8.258Zm-21.5 0A10.696 10.696 0 0 1 7.41 9.489l15.132 15.133a10.75 10.75 0 0 1-17.625-8.258Z"
    />
  </svg>
);
export const CloudRain = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill={props?.color}
      d="m19.897 23.997-3.91 5.864a.98.98 0 0 1-1.355.27.977.977 0 0 1-.271-1.355l3.909-5.864a.977.977 0 1 1 1.627 1.085Zm8.959-12.758a9.294 9.294 0 0 1-9.284 9.284h-2.898l-3.618 5.428a.977.977 0 1 1-1.627-1.084l2.896-4.344H9.8A6.352 6.352 0 1 1 10.89 7.91a9.294 9.294 0 0 1 17.967 3.328Zm-1.955 0a7.337 7.337 0 0 0-14.659-.433.98.98 0 0 1-1.954-.112 9.04 9.04 0 0 1 .094-.883 4.398 4.398 0 1 0-.583 8.758h9.773a7.338 7.338 0 0 0 7.33-7.33Z"
    />
  </svg>
);
