import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import html2canvas from "html2canvas";

export function capitalizeFirstWord(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export const STORAGE_URL = "https://storage.googleapis.com/3d-container/";
export const BASEURL = "https://dashbo-432309.ue.r.appspot.com/v1/";
// export const BASEURL = "https://bayccollection.ue.r.appspot.com/v1/";
// export const BASEURL = "http://localhost:3001/v1/";

export function GPUChecker(setPixelRatio) {
  const canvas = document.createElement("canvas");
  const gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

  if (gl) {
    const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    const gpuRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
    if (
      gpuRenderer.toLowerCase().includes("intel") ||
      gpuRenderer.toLowerCase().includes("mali")
    ) {
      setPixelRatio(2);
    } else {
      setPixelRatio(3);
    }
  } else {
    setPixelRatio(2);
  }
}
export const handleFullscreen = (elementRef, setFullScreen) => {
  if (elementRef.current) {
    if (elementRef.current.requestFullscreen) {
      elementRef.current.requestFullscreen();
    } else if (elementRef.current.mozRequestFullScreen) {
      // Firefox
      elementRef.current.mozRequestFullScreen();
    } else if (elementRef.current.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elementRef.current.webkitRequestFullscreen();
    } else if (elementRef.current.msRequestFullscreen) {
      // IE/Edge
      elementRef.current.msRequestFullscreen();
    }
    setFullScreen(true);
  }
};
export const exitFullscreen = (elementRef, setFullScreen) => {
  if (elementRef.current) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }
    setFullScreen(false);
  }
};
export function GetTouchDevices() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
export function rgba2hex(rgbaString) {
  // Extract the RGBA components from the string
  const match = rgbaString.match(/(\d+(\.\d+)?)/g);
  if (!match || match.length < 4) {
    return null; // Invalid input
  }

  // Convert each component to an integer
  const [r, g, b] = match.map(Number);

  // Convert to hex and format the string
  const rInt = Math.round(r);
  const gInt = Math.round(g);
  const bInt = Math.round(b);
  const hexCode = `#${rInt.toString(16).padStart(2, "0")}${gInt
    .toString(16)
    .padStart(2, "0")}${bInt.toString(16).padStart(2, "0")}`;

  return hexCode;
}

const ffmpeg = new FFmpeg();

export async function startRecording(
  id,
  fileName,
  setRecording,
  duration = 10,
  audioRef,
  isPlaying,
  setConverting,
  downloadType,
  unityProvider
) {
  setRecording(true);
  try {
    const chunks = [];
    let canvasElt = document.getElementById(id);
    let canvasElt1 = canvasElt;
    console.log(canvasElt, canvasElt1);

    const videoStream = canvasElt1.captureStream();
    const audioStream = canvasElt1.captureStream().getAudioTracks();
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    let combinedStream;
    console.log(audioStream);

    if (isIOS) {
      const mimeType = "video/webm;codecs=vp8,opus";
      const fallbackMimeType = "video/mp4;codecs=avc1.42E01E,mp4a.40.2";
      const selectedMimeType = MediaRecorder.isTypeSupported(mimeType)
        ? mimeType
        : MediaRecorder.isTypeSupported(fallbackMimeType)
        ? fallbackMimeType
        : "video/webm";

      try {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const source = audioContext.createMediaElementSource(audioRef.current);
        const destination = audioContext.createMediaStreamDestination();
        source.connect(destination);
        source.connect(audioContext.destination);

        combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream,
        ]);
      } catch (e) {
        console.warn("Failed to capture audio stream:", e);
        combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
      }

      const recorderOptions = {
        mimeType: selectedMimeType,
        videoBitsPerSecond: 2500000, // 2.5 Mbps
        audioBitsPerSecond: 128000, // 128 kbps
      };

      const rec = new MediaRecorder(combinedStream, recorderOptions);

      rec.onerror = (e) => {
        console.error("MediaRecorder error:", e);
        setRecording(false);
      };

      rec.ondataavailable = (e) => chunks.push(e.data);
      rec.onstop = async (e) => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        const url = URL.createObjectURL(blob);
        const extension = selectedMimeType.includes("mp4") ? "mp4" : "webm";
        setRecording(false);

        // Create a container for preview and download
        const container = document.createElement("div");
        container.style.cssText = `
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #000;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 0 10px rgba(0,0,0,0.3);
          z-index: 9999;
          max-width: 90vw;
          max-height: 90vh;
          overflow: auto;
          `;

        // Add video preview
        const video = document.createElement("video");
        video.src = url;
        video.controls = true;
        video.style.maxWidth = "100%";
        video.style.marginBottom = "15px";
        container.appendChild(video);

        // Add download button
        const downloadBtn = document.createElement("button");
        downloadBtn.textContent = "Download Video";
        downloadBtn.style.cssText = `
            display: block;
            width: 100%;
            padding: 10px;
            border:"1px solid #fff";
            blackground: #000 !important;
            color: #000;
            border-radius: 5px;
            cursor: pointer;
            margin-bottom: 10px;
            `;

        // Add close button
        const closeBtn = document.createElement("button");
        closeBtn.textContent = "Close";
        closeBtn.style.cssText = `
            display: block;
            width: 100%;
            padding: 10px;
            border:"1px solid #fff";
            blackground: #000;
            color: #000;
            border-radius: 5px;
            cursor: pointer;
            `;

        // Handle download
        downloadBtn.onclick = () => {
          // For iOS, create a temporary link and trigger download
          const tempLink = document.createElement("a");
          tempLink.href = url;
          tempLink.download = "LetsApe_" + `.${extension}`;
          tempLink.style.display = "none";
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
        };

        // Handle close
        closeBtn.onclick = () => {
          document.body.removeChild(container);
          URL.revokeObjectURL(url);
        };

        container.appendChild(downloadBtn);
        container.appendChild(closeBtn);
        document.body.appendChild(container);
      };
      rec.start(1000);
    } else {
      try {
        combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream,
        ]);
      } catch (e) {
        console.warn("Failed to capture audio stream:", e);
        combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
      }

      const rec = new MediaRecorder(combinedStream, {
        mimeType: GetTouchDevices()
          ? "video/webm; codecs=vp8,opus"
          : "video/webm",
      });

      rec.ondataavailable = (e) => chunks.push(e.data);
      rec.onstop = async (e) => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        await convertAndExportVid(
          blob,
          fileName,
          setRecording,
          setConverting,
          downloadType
        );
      };

      rec.start();
      setTimeout(() => {
        rec.stop();
      }, parseInt(duration) * 1000);
    }
  } catch (err) {
    console.log(err, "errerrerrerrerrerrerr");
  }
}
ffmpeg.on("log", ({ message }) => {
  console.log(message);
});

export async function convertAndExportVid(
  blob,
  fileName,
  setRecording,
  setConverting,
  downloadType,
  animation
) {
  try {
    await ffmpeg.load();
    const data = await fetchFile(blob);
    await ffmpeg.writeFile("input.mp4", data);
    setRecording(false);
    setConverting(true);
    const scale = () => {
      switch (downloadType) {
        case "HighRes":
          return "scale=1600:900";
        case "UltraHD":
          return "scale=1920:1080";
      }
    };
    await ffmpeg.exec([
      "-i",
      "input.mp4",
      "-codec",
      "copy", // Speed up conversion
      "-crf",
      "15",
      "-ar",
      "44100",
      "-c:v",
      "libx264", // H.264 codec
      "-r",
      "30",
      "-pix_fmt",
      "yuv420p", // Ensure QuickTime compatibility
      "-vf",
      "scale=1920:1080", // Maintain resolution (adjust as needed)
      "-preset",
      "ultrafast", // Speed up conversion
      "-movflags",
      "faststart",
      "output.mp4",
    ]);

    const mp4Data = await ffmpeg.readFile("output.mp4");
    const mp4Blob = new Blob([mp4Data], { type: "video/mp4" });

    const url = URL.createObjectURL(mp4Blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}${
      animation !== "None" ? "_" + animation : ""
    }.mp4`;
    a.click();
    setConverting(false);
    URL.revokeObjectURL(url);
  } catch (err) {
    console.log(err);
    setRecording(false);
  }
}

export const gotoUrl = (url) => {
  return window.open(url, "_blank");
};

export const htmlToImageConvert = async (
  name,
  elementRef,
  setFullScreen = null,
  element = "#videoAnimation"
) => {
  try {
    const data = await html2canvas(document.querySelector(element), {
      allowTaint: true,
      useCORS: true,
      scale: 4,
    });
    var anchorTag = document.createElement("a");
    anchorTag.download = "LetsApe_" + "NFT-preview" + ".jpg";
    anchorTag.href = data.toDataURL();
    anchorTag.target = "_blank";
    anchorTag.click();
    if (setFullScreen) {
      exitFullscreen(elementRef, setFullScreen);
    }
  } catch (err) {
    console.log(err);
  }
};
