import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";

import * as React from "react";
import ColorPicker from "react-best-gradient-color-picker";
import { BiExport } from "react-icons/bi";
import { FaChevronDown } from "react-icons/fa";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  convertAndExportVid,
  exitFullscreen,
  GetTouchDevices,
  GPUChecker,
  handleFullscreen,
  htmlToImageConvert,
  rgba2hex,
  STORAGE_URL,
} from "../../utils";
import { ConvertingVideoModal } from "../ConvertingVideoModal/ConvertingVideoModal";
import FooterHome from "../footer/FooterHome";
import "../Loader.css";
import MobileFooter from "../MobileFooter";
import { UploadStickerDialog } from "../ShareDialog/ShareDialog";
import ModelTool from "./ModelTool";
import {
  CaretDoubleDown,
  CircleNotch,
  CloudRain,
  CoinVertical,
  Prohibit,
  SpinnerBall,
  TrendUp,
} from "../../assets/svgs/AnimationIcons";
import { useNavigate } from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",

  width: "60%",
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#0054FA",
  },
}));

function LetsApe() {
  const { palette, ...theme } = useTheme();
  const navigate = useNavigate();
  const [fullScreen, setFullScreen] = React.useState(false);
  const elementRef = React.useRef(null);

  const [loadingName, setLoadingName] = React.useState(
    "Gathering intergalactic building blocks..."
  );
  const [pixelRatio, setPixelRatio] = React.useState(null);
  const isMobile = useMediaQuery("(max-width:599px)");
  const isSmall = useMediaQuery("(max-width:960px)");

  React.useEffect(() => {
    GPUChecker(setPixelRatio);
  }, []);

  const unity = useUnityContext({
    loaderUrl:
      "https://c1bc7d76-c18d-4a77-82ec-bbaf085f81ef.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/5c7d4c02-6fa2-4949-8d39-fa74b9d93530/release_by_badge/latest/entry_by_path/content/?path=LetsApe.loader.js",
    frameworkUrl:
      "https://c1bc7d76-c18d-4a77-82ec-bbaf085f81ef.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/5c7d4c02-6fa2-4949-8d39-fa74b9d93530/release_by_badge/latest/entry_by_path/content/?path=LetsApe.framework.js.unityweb",
    dataUrl:
      "https://c1bc7d76-c18d-4a77-82ec-bbaf085f81ef.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/5c7d4c02-6fa2-4949-8d39-fa74b9d93530/release_by_badge/latest/entry_by_path/content/?path=LetsApe.data.unityweb",
    codeUrl:
      "https://c1bc7d76-c18d-4a77-82ec-bbaf085f81ef.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/5c7d4c02-6fa2-4949-8d39-fa74b9d93530/release_by_badge/latest/entry_by_path/content/?path=LetsApe.wasm.unityweb",
    // streamingAssetsUrl:
    //   "https://c1bc7d76-c18d-4a77-82ec-bbaf085f81ef.client-api.unity3dusercontent.com/client_api/v1/environments/production/buckets/5c7d4c02-6fa2-4949-8d39-fa74b9d93530/release_by_badge/latest/entry_by_path/content/?path=StreamingAssets",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
      premultipliedAlpha: false,
      alpha: false,
      antialias: true,
      powerPreference: "high-performance",
    },
  });

  React.useEffect(() => {
    let percentProgress = unity?.loadingProgression * 100;
    if (percentProgress > 0 && percentProgress < 20) {
      setLoadingName(
        "Counting polygons, one by one (seriously, there are a lot)..."
      );
    }
    if (percentProgress > 20 && percentProgress < 40) {
      setLoadingName("Warming up our virtual 3D printing press...");
    }
    if (percentProgress > 40 && percentProgress < 60) {
      setLoadingName("Summoning the 3D gnomes to do their thing...");
    }
    if (percentProgress > 60 && percentProgress < 80) {
      setLoadingName(
        "Rounding up all the lost 3D sheep... just kidding, it's code!"
      );
    }
    if (percentProgress > 80 && percentProgress < 100) {
      setLoadingName("Holding back impatient 3D squirrels - they're nuts!");
    }
  }, [unity?.loadingProgression]);
  const [height, setHeight] = React.useState(200); // Initial height of the container
  // Handle dragging (for both mouse and touch)
  const handleDrag = (e) => {
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    const newHeight = window.innerHeight - clientY; // Calculate height from bottom
    if (newHeight >= 200 && newHeight <= 500) {
      // Set height limits
      setHeight(newHeight);
    }
  };

  const stopDragging = () => {
    window.removeEventListener("mousemove", handleDrag);
    window.removeEventListener("mouseup", stopDragging);
    window.removeEventListener("touchmove", handleDrag);
    window.removeEventListener("touchend", stopDragging);
  };

  const startDragging = (e) => {
    e.preventDefault(); // Prevents unwanted interactions
    window.addEventListener("mousemove", handleDrag);
    window.addEventListener("mouseup", stopDragging);
    window.addEventListener("touchmove", handleDrag);
    window.addEventListener("touchend", stopDragging);
  };
  const [panels, setPanels] = React.useState([
    {
      name: "Coin Animations",
      selected: true,
      type: "CoinGenerator",
      icon: STORAGE_URL + "letsApe/coinIcon.svg",
      varaints: [],
    },
    {
      name: "Coin Still Images",
      selected: false,
      type: "Still",
      icon: STORAGE_URL + "letsApe/coinIcon.svg",
      varaints: [],
    },
    {
      name: "Coin 3D Model",
      selected: false,
      type: "3D",
      icon: STORAGE_URL + "letsApe/coinIcon.svg",
      varaints: [],
    },
  ]);

  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "",
          height: "100vh",
          width: "100vw",
          backgroundImage: `url(https://storage.googleapis.com/3d-container/letsApe/BG.png)`,
          backgroundColor: `#000`,
          backgroundSize: "cover",
        }}
      >
        {isSmall ? (
          <>
            <img
              style={{
                height: "80px",
                position: "absolute",
                top: "10px",
                left: "2vw",
                width: "auto",
              }}
              onClick={() => navigate(-1)}
              src={STORAGE_URL + "letsApe/logoLetsape.png"}
            />
            <Box
              sx={{
                width: "100%",
                justifyContent: "flex-start",
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #2e2e2e",
                  borderRadius: 3,
                  mt: "120px",
                  height: "35%",
                  backdropFilter: "blur(10px)",
                  marginInline: "0",
                  width: "96%",
                }}
              >
                <Box
                  ref={elementRef}
                  onDoubleClick={() =>
                    fullScreen
                      ? exitFullscreen(elementRef, setFullScreen)
                      : handleFullscreen(elementRef, setFullScreen)
                  }
                  sx={{
                    width: fullScreen ? "100vw" : "100%",
                    // height: "100%",
                    height: fullScreen ? "100vh" : "100%",
                  }}
                  id="videoAnimation"
                >
                  {!unity?.isLoaded && (
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "#000",
                        borderRadius: fullScreen ? "0px" : 3,
                        display: unity?.isLoaded ? "none" : "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "800",
                          fontFamily: "var(--font-i_r)",
                          fontSize: isMobile ? "12px" : "1vw",
                          mb: 2,
                          width: "100%",
                          textAlign: "center",
                          textTransform: "capitalize",
                          color: "#C4C4C4",
                        }}
                      >
                        {loadingName}
                      </Typography>
                      <BorderLinearProgress
                        variant="determinate"
                        value={unity?.loadingProgression * 100}
                      />
                    </Box>
                  )}
                  {pixelRatio && (
                    <Unity
                      style={{
                        width: unity?.isLoaded ? "100%" : "0%",
                        height: unity?.isLoaded ? "100%" : "0%",
                        borderRadius: fullScreen ? "0px" : 3,
                      }}
                      devicePixelRatio={pixelRatio}
                      unityProvider={unity?.unityProvider}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  height: `${height}px`,
                  minHeight: "300px",
                  position: "absolute",
                  bottom: 0,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "scroll",
                  justifyContent: "space-between",
                  backdropFilter: "blur(10px)",
                  // border: "1px solid #2e2e2e",
                  borderRadius: 3,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: -10,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "100px",
                    height: "20px",
                    backgroundColor: "gray",
                    borderRadius: "5px",
                    cursor: "ns-resize",
                  }}
                  onMouseDown={startDragging}
                  onTouchStart={startDragging} // Add touch support
                />
                <Box
                  className="no-scroll"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    height: `${height}px`,
                    overflowY: "scroll",
                    [theme.breakpoints.down("sm")]: {},
                  }}
                >
                  <RenderItems
                    items={panels.find((e) => e.selected)}
                    panels={panels}
                    setPanels={setPanels}
                    unity={unity}
                    elementRef={elementRef}
                    setFullScreen={setFullScreen}
                  />
                </Box>
                <Box
                  className="no-scroll"
                  sx={{
                    borderTop: "1px solid #2e2e2e",
                    display: "flex",
                    flexDirection: "row",
                    overflow: "scroll",
                    justifyContent: "space-between",
                    // height: "10vh",
                    width: "100vw",
                    p: 2,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  {panels.map((panel) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          background: "#121212",
                          cursor: "pointer",
                          background: `${panel?.selected ? "#001237" : "#000"}`,
                          border: `1px solid ${
                            panel?.selected ? "#0054FA" : "#0054FA"
                          }`,
                          p: 1,
                          width: "20%",
                          minWidth: "150px",
                          borderRadius: "10px",
                          mr: 2,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          const updated = panels.map((pa, i) => {
                            if (panel.name === pa.name) {
                              unity?.sendMessage("Manager", "ChangeTab", i);
                              return {
                                ...pa,
                                selected: true,
                              };
                            }
                            return {
                              ...pa,
                              varaints:
                                pa.name === "Colors" ? [] : pa?.varaints,
                              selected: false,
                            };
                          });
                          setPanels(updated);
                        }}
                      >
                        <img
                          style={{
                            marginInline: "0.8vw",
                            width: isMobile ? "20%" : "10%",
                          }}
                          src={panel.icon}
                        />
                        <Typography
                          sx={{
                            color: panel?.selected ? "#fff" : "#0054FA",
                            fontSize: "12px",
                            textAlign: "center",
                          }}
                        >
                          {panel.name}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <img
              style={{
                height: "80px",
                position: "absolute",
                top: "0.5vw",
                left: "2vw",
                width: "auto",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
              src={STORAGE_URL + "letsApe/logoLetsape.png"}
            />
            <Box
              sx={{
                width: "96%",
                marginTop: "100px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "82%",
                borderRadius: "0.8vw",
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                  marginTop: "10px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "80vh",
                  width: "15%",
                  border: "1px solid #2e2e2e",
                  p: 2,
                  borderRadius: 3,

                  backdropFilter: "blur(10px)",
                  [theme.breakpoints.down("md")]: {
                    width: "100%",
                    height: "10vh",
                    mt: 0,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  },
                }}
              >
                {panels.map((panel) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        background: "#121212",
                        cursor: "pointer",
                        border: panel?.selected ? "1px solid #0054FA" : "",
                        borderRadius: "0.5vw",
                        my: 2,
                        px: 1,
                        py: 2,
                        [theme.breakpoints.down("md")]: {
                          width: "20%",
                          borderRadius: "0.5vw",
                          my: 0,
                          px: 0,
                          py: 0,
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                      onClick={() => {
                        const updated = panels.map((pa, i) => {
                          if (panel.name === pa.name) {
                            unity?.sendMessage("Manager", "ChangeTab", i);
                            return {
                              ...pa,
                              selected: true,
                            };
                          }
                          return {
                            ...pa,
                            varaints: pa.name === "Colors" ? [] : pa?.varaints,
                            selected: false,
                          };
                        });
                        setPanels(updated);
                      }}
                    >
                      <img
                        style={{
                          marginInline: "0.8vw",
                          width: isMobile ? "20%" : "10%",
                        }}
                        src={panel.icon}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "14px",
                          textAlign: "center",
                          [theme.breakpoints.down("md")]: {
                            fontSize: "12px",
                          },
                        }}
                      >
                        {panel.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box
                className="exist"
                sx={{ width: "80%", display: "flex", flexDirection: "row" }}
              >
                <Box
                  className="no-scroll"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "80vh",

                    width: "28%",
                    border: "1px solid #2e2e2e",
                    ml: 2,
                    borderRadius: 3,
                    backdropFilter: "blur(10px)",
                    overflowY: "scroll",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                      height:
                        panels?.find((e) => e.selected)?.name === "Text"
                          ? "40vh"
                          : "20vh",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    },
                    [theme.breakpoints.down("sm")]: {
                      my: 1,
                      height:
                        panels?.find((e) => e.selected)?.name === "Text"
                          ? "35vh"
                          : "14vh",
                    },
                  }}
                >
                  <RenderItems
                    items={panels.find((e) => e.selected)}
                    panels={panels}
                    setPanels={setPanels}
                    unity={unity}
                    elementRef={elementRef}
                    setFullScreen={setFullScreen}
                  />
                </Box>
                <Box
                  sx={{
                    border: "1px solid #2e2e2e",
                    ml: 2,
                    borderRadius: 3,
                    backdropFilter: "blur(10px)",
                    [theme.breakpoints.down("md")]: {
                      marginInline: "0",
                      width: "100%",
                    },
                  }}
                >
                  <Box
                    ref={elementRef}
                    onDoubleClick={() =>
                      fullScreen
                        ? exitFullscreen(elementRef, setFullScreen)
                        : handleFullscreen(elementRef, setFullScreen)
                    }
                    sx={{
                      width: fullScreen
                        ? "100vw"
                        : { xl: "55vw", lg: "55vw", md: "55vw", sm: "96vw" },
                      // height: "100%",
                      height: fullScreen
                        ? "100vh"
                        : {
                            xl: "80vh",
                            lg: "80vh",
                            md: "80vh",
                            sm: "50vh",
                          },
                    }}
                    id="videoAnimation"
                  >
                    {!unity?.isLoaded && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          background: "#000",
                          borderRadius: fullScreen ? "0px" : "0.8vw",
                          display: unity?.isLoaded ? "none" : "flex",
                          alignItems: "center",
                          // margin: "auto",
                          flexDirection: "column",
                          justifyContent: "center",
                          [theme.breakpoints.down("sm")]: {
                            height: "30vh",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "800",
                            fontFamily: "var(--font-i_r)",
                            fontSize: isMobile ? "12px" : "1vw",
                            mb: 2,
                            width: "100%",
                            textAlign: "center",
                            textTransform: "capitalize",
                            color: "#C4C4C4",
                          }}
                        >
                          {loadingName}
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={unity?.loadingProgression * 100}
                        />
                      </Box>
                    )}
                    {pixelRatio && (
                      <Unity
                        style={{
                          width: unity?.isLoaded ? "100%" : "0%",
                          height: unity?.isLoaded ? "100%" : "0%",
                          borderRadius: fullScreen ? "0px" : "0.8vw",
                        }}
                        devicePixelRatio={pixelRatio}
                        unityProvider={unity?.unityProvider}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      {!isSmall && <FooterHome ddu />}
    </Box>
  );
}

export default LetsApe;

const RenderItems = ({
  items,
  panels,
  setPanels,
  unity,
  setFullScreen,
  elementRef,
}) => {
  const [open, setOpen] = React.useState({ mode: 0, active: false });
  const [animation, setAnimation] = React.useState("None");
  const [converting, setConverting] = React.useState(false);
  const [recording, setRecording] = React.useState(false);
  const [selectedColorPanel, setSelectedColorPanel] = React.useState(null);
  const audioRef = React.useRef(null);

  const [showColorSelector, setShowColorSelector] = React.useState(false);
  const [assetLoaded, setAssetLoaded] = React.useState(true);
  const [selectedAsset, setSelectedAsset] = React.useState(null);
  const [rec, setRec] = React.useState(null);

  React.useEffect(() => {
    if (unity?.addEventListener) {
      unity?.addEventListener("StopRecording", (e) => {
        console.log("StopRecordingStopRecording");

        if (rec) {
          rec.stop();
        }
      });

      return () => {
        unity?.removeEventListener("StopRecording", () => {});
      };
    }
  }, [unity?.addEventListener, unity?.removeEventListener, rec]);
  React.useEffect(() => {
    if (rec) {
      const chunks = [];
      rec.onerror = (e) => {
        console.error("MediaRecorder error:", e);
        setRecording(false);
      };
      rec.ondataavailable = (e) => chunks.push(e.data);
      rec.onstop = async (e) => {
        const blob = new Blob(chunks, { type: "video/mp4" });
        await convertAndExportVid(
          blob,
          fonts.text,
          setRecording,
          setConverting,
          "UltraHD",
          animation
        );
      };
      rec.start(1000);
    }
  }, [rec]);

  async function startRecording(id, setRecording, audioRef) {
    setRecording(true);
    try {
      const chunks = [];
      let canvasElt = document.getElementById(id);
      let canvasElt1 = canvasElt?.firstChild;
      console.log(canvasElt1);

      const videoStream = canvasElt1.captureStream();
      const audioStream = canvasElt1.captureStream().getAudioTracks();
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      let combinedStream;
      console.log(audioStream);

      if (isIOS) {
        const mimeType = "video/webm;codecs=vp8,opus";
        const fallbackMimeType = "video/mp4;codecs=avc1.42E01E,mp4a.40.2";
        const selectedMimeType = MediaRecorder.isTypeSupported(mimeType)
          ? mimeType
          : MediaRecorder.isTypeSupported(fallbackMimeType)
          ? fallbackMimeType
          : "video/webm";

        try {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          const source = audioContext.createMediaElementSource(
            audioRef.current
          );
          const destination = audioContext.createMediaStreamDestination();
          source.connect(destination);
          source.connect(audioContext.destination);

          combinedStream = new MediaStream([
            ...videoStream.getVideoTracks(),
            ...audioStream,
          ]);
        } catch (e) {
          console.warn("Failed to capture audio stream:", e);
          combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
        }

        const recorderOptions = {
          mimeType: selectedMimeType,
          videoBitsPerSecond: 2500000, // 2.5 Mbps
          audioBitsPerSecond: 128000, // 128 kbps
        };
        setRec(new MediaRecorder(combinedStream, recorderOptions));
      } else {
        try {
          combinedStream = new MediaStream([
            ...videoStream.getVideoTracks(),
            ...audioStream,
          ]);
        } catch (e) {
          console.warn("Failed to capture audio stream:", e);
          combinedStream = new MediaStream([...videoStream.getVideoTracks()]);
        }

        setRec(
          new MediaRecorder(combinedStream, {
            mimeType: GetTouchDevices()
              ? "video/webm; codecs=vp8,opus"
              : "video/webm",
          })
        );
      }
    } catch (err) {
      console.log(err, "errerrerrerrerrerrerr");
    }
  }
  const [fonts, setFonts] = React.useState({
    text: "$Coin",
    text2: "$Coin",
    color: "#191919",
    background: "#002B40",
    size: 10,
    family: "Choose Font",
    type: "normal",
  });
  const { palette, ...theme } = useTheme();

  const isMobile = useMediaQuery("(max-width:599px)");
  const colorRef = React.useRef(null);

  const handleClickOutsideColor = (event) => {
    if (colorRef.current && !colorRef.current.contains(event.target)) {
      setShowColorSelector(false);
    }
  };
  console.log(selectedColorPanel);

  const handleColorChange = (newColor) => {
    const hex = newColor.includes("linear") ? newColor : rgba2hex(newColor);

    if (selectedColorPanel === 1) {
      unity?.sendMessage("Manager", "ChangeCoinColor", hex);
      setFonts({
        ...fonts,
        color: hex,
      });
    }
    if (selectedColorPanel === 2) {
      unity?.sendMessage("Manager", "ChangeBackgroundColor", hex);
      setFonts({
        ...fonts,
        background: hex,
      });
    }
  };
  React.useEffect(() => {
    if (unity?.addEventListener) {
      unity?.addEventListener("AssetLoaded", (e) => {
        setAssetLoaded(e ? true : false);
      });
      return () => {
        unity?.removeEventListener("AssetLoaded", () => {});
      };
    }
  }, [unity?.addEventListener, unity?.removeEventListener, window]);
  React.useEffect(() => {
    if (showColorSelector) {
      document.addEventListener("mousedown", handleClickOutsideColor);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideColor);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideColor);
    };
  }, [showColorSelector]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        className="no-scroll"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          height: "100%",
          justifyContent: "flex-start",
          [theme.breakpoints.down("md")]: {
            display: items?.type === "CoinGenerator" ? "block" : "flex",
            justifyContent: "flex-start",
            flexWrap: "nowrap",
            "::-webkit-scrollbar-track": {
              backgroundColor: "transparant !important",
            },
            "::-webkit-scrollbar-thumb": {
              backgroundColor: "transparant !important",
            },
            "::-webkit-scrollbar": {
              width: "0px !important",
            },
          },
        }}
      >
        <>
          <Box sx={{ width: "100%" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "16px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "14px",
                    mb: 1,
                    width: "100%",
                    textAlign: "left",
                    textTransform: "capitalize",
                    color: "#C4C4C4",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "12px",
                      mb: 0,
                    },
                  }}
                >
                  Coin Text Front
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    className="textarea"
                    style={{
                      border: "none",
                      width: "100%",
                      borderRadius: isMobile ? "5px" : "10px",
                      padding: isMobile ? "5px" : "10px",
                      height: isMobile ? "30px" : "40px",
                      background: "#222423",
                      color: "#fff",
                    }}
                    value={fonts.text}
                    onChange={(e) => {
                      if (e.target.value?.length < 8) {
                        unity?.sendMessage(
                          "Manager",
                          "ChangeText",
                          e.target.value
                        );
                        setFonts({ ...fonts, text: e.target.value });
                      }
                    }}
                    placeholder="Text"
                  />
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "14px",
                    mb: 1,
                    width: "100%",
                    textAlign: "left",
                    textTransform: "capitalize",
                    color: "#C4C4C4",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "12px",
                      mb: 0,
                    },
                  }}
                >
                  Coin Text Back
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <input
                    className="textarea"
                    style={{
                      border: "none",
                      width: "100%",
                      borderRadius: isMobile ? "5px" : "10px",
                      padding: isMobile ? "5px" : "10px",
                      height: isMobile ? "30px" : "40px",
                      background: "#222423",
                      color: "#fff",
                    }}
                    value={fonts.text2}
                    onChange={(e) => {
                      if (e.target.value?.length < 8) {
                        unity?.sendMessage(
                          "Manager",
                          "ChangeText2",
                          e.target.value
                        );
                        setFonts({ ...fonts, text2: e.target.value });
                      }
                    }}
                    placeholder="Text"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  background: "#2e2e2e",
                  mt: 1,
                }}
              />
              <Box sx={{ width: "100%", paddingTop: 2 }}>
                <Typography
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "14px",
                    width: "100%",
                    textAlign: "left",
                    textTransform: "capitalize",
                    color: "#C4C4C4",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "12px",
                      mb: 0,
                    },
                  }}
                >
                  Choose Coin Image(s) :
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {[1, 2].map((e) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: "800",
                            fontFamily: "var(--font-i_r)",
                            fontSize: "14px",
                            width: "100%",
                            textAlign: "center",
                            textTransform: "capitalize",
                            color: "#C4C4C4",
                            [theme.breakpoints.down("md")]: {
                              fontSize: "12px",
                              mb: 0,
                            },
                          }}
                        >
                          Side {e}
                        </Typography>
                        <Box
                          onClick={() => {
                            setOpen({ mode: e, active: true });
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          <Avatar
                            sx={{
                              width: {
                                xs: "16vw",
                                lg: "5vw",
                                xl: "5vw",
                                md: "50px",
                                sm: "50px",
                              },
                              height: {
                                xs: "16vw",
                                lg: "5vw",
                                xl: "5vw",
                                md: "50px",
                                sm: "50px",
                              },
                            }}
                            variant="square"
                            src={STORAGE_URL + "letsApe/Upload.png"}
                            alt={STORAGE_URL + "letsApe/Upload.png"}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  background: "#2e2e2e",
                  mb: 1,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      my: 1,
                      width: "100%",
                      textAlign: "left",
                      color: "#C4C4C4",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "12px",
                        mb: 0,
                      },
                    }}
                  >
                    Coin Color :
                  </Typography>
                  <Box
                    style={{
                      marginBottom: "10px",
                    }}
                    onClick={() => {
                      setSelectedColorPanel(1);
                      setShowColorSelector(!showColorSelector);
                    }}
                  >
                    <Box
                      sx={{
                        height: "30px",
                        width: "80%",
                        border: "1px solid #2e2e2e",
                        borderRadius: "4px",
                        marginRight: "10px",
                        backgroundColor: fonts.color ? fonts.color : null,
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      my: 1,
                      width: "100%",
                      textAlign: "left",
                      color: "#C4C4C4",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "12px",
                        mb: 0,
                      },
                    }}
                  >
                    Background Color :
                  </Typography>
                  <Box
                    style={{
                      marginBottom: "10px",
                    }}
                    onClick={() => {
                      setSelectedColorPanel(2);
                      setShowColorSelector(!showColorSelector);
                    }}
                  >
                    <Box
                      sx={{
                        height: "30px",
                        width: "80%",
                        border: "1px solid #2e2e2e",
                        borderRadius: "4px",
                        marginRight: "10px",
                        backgroundColor: fonts.background
                          ? fonts.background
                          : null,
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              {showColorSelector && (
                <Box ref={colorRef}>
                  <ColorPicker
                    height={"150"}
                    width={"300"}
                    value={
                      selectedColorPanel === 1 ? fonts.color : fonts.background
                    }
                    hidePresets
                    hideInputs
                    hideControls
                    hideOpacity
                    onChange={(e) => handleColorChange(e)}
                  />
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  height: "1px",
                  background: "#2e2e2e",
                  mt: 1,
                }}
              />
              {items?.type === "CoinGenerator" && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "800",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",

                      textAlign: "left",
                      textTransform: "capitalize",
                      color: "#C4C4C4",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "12px",
                        mb: 0,
                      },
                    }}
                  >
                    Choose Animation :
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    {" "}
                    {[
                      { key: "Spin", value: "Spin", icon: SpinnerBall },
                      { key: "Rain", value: "Rain", icon: CloudRain },
                      {
                        key: "Spin & Rain",
                        value: "Spin & Rain",
                        icon: CircleNotch,
                      },
                      { key: "Roll", value: "Roll", icon: CoinVertical },
                      { key: "Jump", value: "Jump", icon: TrendUp },
                      { key: "Fall", value: "Fall", icon: CaretDoubleDown },
                      { key: "None", value: "None", icon: Prohibit },
                    ]?.map((ani) => {
                      return (
                        <Box
                          onClick={() => {
                            setAnimation(ani.key);
                            unity?.sendMessage(
                              "Manager",
                              "ChangeAnimation",
                              String(ani.key)
                            );
                          }}
                          sx={{
                            p: "4px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 1,
                            m: 1,
                            width: "27%",
                            background: `${
                              animation === ani.key ? "#001237" : "#000"
                            }`,
                            border: `1px solid ${
                              animation === ani.key ? "#0054FA" : "#000"
                            }`,
                          }}
                        >
                          <ani.icon
                            color={animation === ani.key ? "#0054FA" : "#fff"}
                          />
                          <Typography
                            sx={{
                              fontWeight: "800",
                              mt: 1,
                              textAlign: "center",
                              fontFamily: "var(--font-i_r)",
                              fontSize: "10px",
                              color: animation === ani.key ? "#0054FA" : "#fff",
                              [theme.breakpoints.down("md")]: {
                                fontSize: "12px",
                              },
                            }}
                          >
                            {ani.key}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Box>
            {items?.type === "CoinGenerator" && (
              <Box
                sx={{
                  float: "center",
                  padding: "2vh",
                }}
              >
                <ModelTool
                  manualJustifyContent="center"
                  icon={BiExport}
                  onClickAction={() => {
                    unity?.sendMessage("Manager", "StartRecording");
                    startRecording(
                      "videoAnimation",
                      (e) => setRecording(e),
                      audioRef
                    );
                  }}
                  name="Export Animation"
                />
              </Box>
            )}
            {items?.type === "Still" && (
              <>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      float: "center",
                      padding: "2vh",
                    }}
                  >
                    <ModelTool
                      manualJustifyContent="center"
                      icon={BiExport}
                      onClickAction={async () => {
                        handleFullscreen(elementRef, setFullScreen);
                        setTimeout(() => {
                          htmlToImageConvert(
                            "fileName",
                            elementRef,
                            setFullScreen
                          );
                        }, 1000);
                      }}
                      name="Export JPG"
                    />
                  </Box>
                </Box>
              </>
            )}
            {items?.type === "3D" && (
              <>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      float: "center",
                      padding: "2vh",
                    }}
                  >
                    <ModelTool
                      manualJustifyContent="center"
                      icon={BiExport}
                      onClickAction={() => {
                        unity?.sendMessage("Manager", "ExportGLB");
                      }}
                      name="Export GLB"
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          {items?.varaints?.map((item) => {
            return (
              <Box
                onClick={() => {
                  if (!assetLoaded) return;
                  setSelectedAsset(item?.name);
                  const updated = items?.varaints?.map((it) => {
                    if (it?.name === item?.name)
                      return {
                        ...it,
                        selected: true,
                      };
                    return {
                      ...it,
                      selected: false,
                    };
                  });
                  setPanels(() =>
                    panels?.map((panel) => {
                      if (panel?.name === items.name)
                        return {
                          ...panel,
                          varaints: updated,
                        };
                      return {
                        ...panel,
                      };
                    })
                  );
                  if (items?.type === "CoinGenerator") {
                    unity?.sendMessage(
                      "Manager",
                      "ChangeBike",
                      item?.name + " " + 1
                    );
                  }
                }}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "10px",
                  borderRadius: "0.8vw",
                }}
              >
                {!assetLoaded && selectedAsset === item?.name && (
                  <Box
                    sx={{
                      position: "absolute",
                      zIndex: 10000,
                    }}
                  >
                    <div className="loader-cloth"></div>
                  </Box>
                )}
                <Avatar
                  sx={{
                    width: {
                      xs: "16vw",
                      lg: "5vw",
                      xl: "5vw",
                      md: "150px",
                      sm: "50px",
                    },
                    height: {
                      xs: "16vw",
                      lg: "5vw",
                      xl: "5vw",
                      md: "150px",
                      sm: "50px",
                    },
                    borderRadius: "0.8vw",
                  }}
                  variant="square"
                  src={item.img}
                  alt={item.id}
                />
              </Box>
            );
          })}
        </>

        {converting && (
          <ConvertingVideoModal
            open={converting}
            duration={3}
            downloadType={"UltraHD"}
          />
        )}

        <UploadStickerDialog
          open={open.active}
          setOpen={() => setOpen({ mode: 0, active: false })}
          onUpload={() => {
            unity?.sendMessage(
              "Manager",
              "UploadTexture",
              open.mode === 1 ? 2 : 1
            );
          }}
        />
      </Box>
    </Box>
  );
};
