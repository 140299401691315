import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "./theme";
import { GlobalTheme } from "./theme/GlobalTheme";
import ComingSoon from "./components/Temp";
import LetsApe from "./components/model3d";
function App() {
  return (
    <ThemeProvider theme={themeSettings}>
      <GlobalTheme theme={themeSettings} />
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/coinGenerator" element={<LetsApe />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
