import React from "react";
import GlobalStyles from "@mui/material/GlobalStyles";

export const GlobalTheme = () => {
  return (
    <GlobalStyles
      styles={{
        html: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        },
        "html,body": {
          overflow: "hidden !important",
          padding: " 0 !important",
        },
        "*, *::before, *::after": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
        a: {
          textDecoration: "none",
        },
      }}
    />
  );
};
