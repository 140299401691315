import React, { useEffect, useState } from "react";

import { useTheme } from "@emotion/react";
import {
  Box,
  Dialog,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import { STORAGE_URL } from "../../utils";
import ConnectWallet from "../buttons/ConnectWallet";

export const ShareDialog = ({
  mode,
  openShare,
  setOpenShare,
  onFBShare,
  onDownlaod,
  onTwitterShare,
  downloadType,
  setDownloadType,
  duration,
  setDuration,
}) => {
  const [fileName, setFileName] = useState(null);
  const [error, setError] = useState("");

  const type = mode === "animate" || mode === "scenes" ? "HighRes" : "PNG";
  useEffect(() => {
    setDownloadType(type);
  }, [type]);
  const { palette } = useTheme();
  const [buttons, setButtons] = useState([
    // {
    //   name: "Save",
    //   selected: true,
    // },
    {
      name: "Download",
      selected: true,
    },
    // {
    //   name: "Share",
    //   selected: false,
    // },
  ]);
  const socialButtons = [
    {
      name: "Twitter",
      icon: "https://storage.googleapis.com/3d-container/social-icons/twitter.png",
      onClick: () => onTwitterShare(),
    },
    {
      name: "Instagram",
      icon: "https://storage.googleapis.com/3d-container/social-icons/instagram.png",
      onClick: () => {},
    },
    {
      name: "Facebook",
      icon: "https://storage.googleapis.com/3d-container/social-icons/facebook.png",
      onClick: () => onFBShare(),
    },
    {
      name: "WhatsApp",
      icon: "https://storage.googleapis.com/3d-container/social-icons/whatsapp.png",
      onClick: () => {},
    },
    {
      name: "Email",
      icon: "https://storage.googleapis.com/3d-container/social-icons/mail.png",
      onClick: () => {},
    },
    {
      name: "Youtube",
      icon: "https://storage.googleapis.com/3d-container/social-icons/youtube.png",
      onClick: () => {},
    },
    {
      name: "Discord",
      icon: "https://storage.googleapis.com/3d-container/social-icons/discord.png",
      onClick: () => {},
    },
    {
      name: "Telegram",
      icon: "https://storage.googleapis.com/3d-container/social-icons/telegram.png",
      onClick: () => {},
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const numberValue = Number(value);
      if (numberValue > 30) {
        setError("Duration cannot exceed 30 seconds");
      } else if (numberValue < 1 && value !== "") {
        setError("Duration cannot be less than 1 second");
      } else {
        setError("");
        setDuration(value);
      }
    } else {
      setError("Please enter a valid number");
    }
  };
  const getTime = () => {
    switch (downloadType) {
      case "LowRes":
        if (duration <= 10) {
          return 15;
        }
        if (duration > 10 && duration <= 20) {
          return 30;
        }
        if (duration > 20) {
          return 1;
        }
      case "HighRes":
        if (duration <= 10) {
          return 30;
        }
        if (duration > 10 && duration <= 20) {
          return 50;
        }
        if (duration > 20) {
          return 1.3;
        }
      case "UltraHD":
        if (duration <= 10) {
          return 2;
        }
        if (duration > 10 && duration <= 20) {
          return 4;
        }
        if (duration > 20) {
          return 6;
        }
      default:
        return "black";
    }
  };
  const getTimeUnit = () => {
    switch (downloadType) {
      case "LowRes":
        if (duration <= 10) {
          return "seconds";
        }
        if (duration > 10 && duration <= 20) {
          return "seconds";
        }
        if (duration > 20) {
          return "minutes";
        }
      case "HighRes":
        if (duration <= 10) {
          return "seconds";
        }
        if (duration > 10 && duration <= 20) {
          return "seconds";
        }
        if (duration > 20) {
          return "minutes";
        }
      case "UltraHD":
        if (duration <= 10) {
          return "minutes";
        }
        if (duration > 10 && duration <= 20) {
          return "minutes";
        }
        if (duration > 20) {
          return "minutes";
        }
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#000",
            zIndex: 10,
            height: "40%",
            border: `1px solid ${palette.button.default}`,
            borderRadius: "20px",
          },
        }}
        onClose={() => setOpenShare(false)}
        open={openShare}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {buttons.map((button, i) => {
              return (
                <Box
                  key={i + "dialogButtons"}
                  sx={{
                    cursor: "pointer",
                    px: 4,
                    py: 1,
                    background: button.selected ? palette.button.default : "",
                  }}
                  onClick={() => {
                    const updated = buttons.map((btn) => {
                      if (btn.name === button.name) {
                        return {
                          ...btn,
                          selected: true,
                        };
                      }
                      return {
                        ...btn,
                        selected: false,
                      };
                    });
                    setButtons(updated);
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontFamily: "var(--font-f_r)",
                      fontSize: {
                        xl: "1vw",
                        lg: "1vw",
                        md: "1vw",
                        xs: "4vw",
                        sm: "4vw",
                      },
                      textTransform: "capitalize",
                      textAlign: "center",
                      color: button.selected ? "black" : "#fff",
                    }}
                  >
                    {button.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <RxCross2
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={() => setOpenShare(false)}
            color={"#F85B03"}
            size={25}
          />
        </Box>
        <Divider
          style={{
            width: "100%",
            height: "1px",
            border: "1px solid #0F0F0F",
          }}
        />
        {buttons.find((e) => e.selected)?.name === "Save" && (
          <Box p={3} display="flex" alignItems="end" width="100%">
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                item
                justifyContent="center"
                alignItems="center"
                lg={8}
                xl={8}
                xs={12}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "14px",
                    color: "#ffff",
                    alignSelf: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  File Name
                </Typography>
                <TextField
                  id="name"
                  variant="outlined"
                  placeholder="Enter file name ..."
                  fullWidth
                  sx={{
                    color: "#fff",
                    fontFamily: "var(--font-i_r)",
                    "& .MuiInputBase-input": { color: "#fff" },
                    "& .MuiInputLabel-root": { color: "#fff" },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: palette.button.default,
                      },
                  }}
                  value={fileName}
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                />
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                xs={12}
                xl={4}
                lg={4}
              >
                <ConnectWallet
                  onClick={() => {}}
                  isHeader
                  label="  save to library"
                  fullWidth
                  sx={{
                    margin: {
                      xl: "2.5vh 0px 0px 0px ",
                      lg: "2.5vh 0px 0px 0px ",
                    },
                  }}
                ></ConnectWallet>
              </Grid>
            </Grid>
          </Box>
        )}
        {buttons.find((e) => e.selected)?.name === "Download" && (
          <>
            <Box p={3} display="flex" alignItems="end" width="100%">
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid
                  item
                  justifyContent="flex-start"
                  alignItems="center"
                  lg={8}
                  xl={8}
                  xs={12}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    File Name
                  </Typography>
                  <TextField
                    id="name"
                    variant="outlined"
                    placeholder="Enter file name ..."
                    fullWidth
                    sx={{
                      color: "#fff",
                      fontFamily: "var(--font-i_r)",
                      "& .MuiInputBase-input": { color: "#fff" },
                      "& .MuiInputLabel-root": { color: "#fff" },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: palette.button.default,
                        },
                    }}
                    value={fileName}
                    onChange={(e) => {
                      setFileName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  justifyContent="flex-start"
                  alignItems="center"
                  lg={4}
                  xl={4}
                  xs={12}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    File Format
                  </Typography>
                  {mode === "animate" || mode === "scenes" ? (
                    <FormControl fullWidth>
                      <Select
                        value={downloadType}
                        onChange={(e) => {
                          setDownloadType(e.target.value);
                        }}
                        sx={{
                          width: "100%",
                          color: "#fff",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#fff",
                          },
                        }}
                        defaultValue={downloadType}
                      >
                        <MenuItem value="LowRes">Low Res</MenuItem>
                        <MenuItem value="HighRes">High Res</MenuItem>
                        <MenuItem value="UltraHD">Ultra HD</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        value={downloadType}
                        onChange={(e) => {
                          setDownloadType(e.target.value);
                        }}
                        sx={{
                          width: "100%",
                          color: "#fff",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: palette.button.default,
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#fff",
                          },
                        }}
                        defaultValue={downloadType}
                      >
                        <MenuItem value="PNG">PNG</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box p={3} display="flex" alignItems="end" width="100%">
              <Grid
                container
                spacing={2}
                justifyContent="right"
                alignItems="center"
              >
                {(mode === "animate" || mode === "scenes") && (
                  <Grid
                    item
                    justifyContent="center"
                    alignItems="center"
                    lg={8}
                    xl={8}
                    xs={12}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "14px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Duration (max 30 seconds)
                    </Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Enter file name ..."
                      fullWidth
                      sx={{
                        color: "#fff",
                        fontFamily: "var(--font-i_r)",
                        "& .MuiInputBase-input": { color: "#fff" },
                        "& .MuiInputLabel-root": { color: "#fff" },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#fff",
                          },
                      }}
                      value={duration}
                      onChange={handleChange}
                      error={Boolean(error)}
                      helperText={error}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  justifyContent="right"
                  alignItems="center"
                  lg={4}
                  xl={4}
                  xs={12}
                >
                  <ConnectWallet
                    label="Download"
                    onClick={() => {
                      if (!duration) return setError("Duration is required");
                      onDownlaod(fileName, duration);
                    }}
                    isHeader
                    fullWidth
                    sx={{ margin: "2.5vh 0px 0px 0px " }}
                  />
                </Grid>
                {(mode === "animate" || mode === "scenes") && (
                  <Grid
                    item
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    lg={12}
                    xl={12}
                    xs={12}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "22px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Note:
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "18px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      It will take aproximately{" "}
                      <span style={{ color: "#0054FA" }}>
                        {getTime()} {getTimeUnit()}{" "}
                      </span>{" "}
                      to download recorded video.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        )}
        {buttons.find((e) => e.selected)?.name === "Share" && (
          <Box p={3} display="flex" alignItems="center" width="100%">
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              {socialButtons.map((btn) => (
                <Grid item key={btn.name} xs={12} sm={6} md={4}>
                  <Box
                    onClick={() => {
                      setOpenShare(false);
                      btn.onClick();
                    }}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      borderRadius: "10px",

                      justifyContent: "center",
                      alignItems: "center",
                      p: 2,
                      m: 2,
                      background: "#181818",
                    }}
                  >
                    <img src={btn.icon} alt={btn.icon} />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        textTransform: "capitalize",
                        fontFamily: "var(--font-f_r)",
                        fontSize: { xl: "1.2em", lg: "1.2em", md: "1em" },
                        color: "#0054FA",
                        ml: 2,
                      }}
                    >
                      {btn.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Dialog>
    </div>
  );
};

export const UploadStickerDialog = ({ open, setOpen, onUpload }) => {
  const isMobile = useMediaQuery("(max-width:599px)");
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableEnforceFocus
        slotProps={{
          backdrop: {
            style: {
              zIndex: 2,
              cursor: "pointer",
              backgroundColor: "#000",
              opacity: "0.7",
            },
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: "#000",
            zIndex: 10,
            height: "40%",
            border: `1px solid #0054FA`,
            borderRadius: "20px",
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            mx: "auto",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontFamily: "var(--font-g)",
              fontSize: isMobile ? "18px" : "1.2vw",
              fontWeight: 600,
              my: 1,
              color: "#fff",
            }}
          >
            UPLOAD AN IMAGE
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "92%",
              marginInline: "auto",
              borderRadius: "0.5vw",
              backgroundColor: "#242424",
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              paddingBlock: 1,
              margin: 1,
            }}
            onClick={() => {
              onUpload();
              setOpen(false);
            }}
          >
            <img
              src={STORAGE_URL + "Upload%20icon.png"}
              style={{ width: "30px", filter: "grayscale(1)" }}
              alt="upload audio file"
            />

            <Typography
              sx={{
                fontWeight: "600",
                fontFamily: "var(--font-i_r)",
                fontSize: isMobile ? "12px" : "0.7vw",
                marginBlock: "0.5vw",
                color: "#C4C4C4",
              }}
            >
              Select your image
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
