import React from "react";
import "../shared.css";
import { STORAGE_URL } from "../../utils";
import ApeChain from "../../assets/images/apeChain.png";
import Arrow from "../../assets/images/arrow_forward.png";
import "./index.css";
import { useNavigate } from "react-router-dom";

function ComingSoon() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: 0,
        margin: 0,
      }}
    >
      <img
        className="logo"
        style={{
          width: "auto",
        }}
        src={STORAGE_URL + "letsApe/logoLetsape.png"}
      />

      <div
        onClick={() => navigate("/coinGenerator")}
        className="btn"
        style={{
          display: "flex",
          alignItems: "center",

          borderRadius: "30px",
          marginBlock: "60px",
          padding: "10px",
          background: "rgb(255 255 255 / 10%)",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            fontSize: "20px",
            color: "#fff",
            textAlign: "center",
            width: "100%",
          }}
        >
          Get Started
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            borderRadius: "50%",
            background: "#0054FA",
            height: "100%",
            padding: "10px",
          }}
        >
          <img
            style={{
              height: "20px",
              width: "auto",
            }}
            src={Arrow}
          />
        </div>
      </div>
      <img
        className="apeChain"
        style={{
          width: "auto",
        }}
        src={ApeChain}
      />
    </div>
  );
}

export default ComingSoon;
