import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import "./styles.css";

const StyledButton = styled(({ smallButton, originHeight, ...rest }) => (
  <Button {...rest} />
))(({ theme, sx, smallButton, originHeight }) => ({
  color: sx?.color || theme.palette.button.default,
  borderColor: theme.palette.button.default,
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(5px)",
  fontFamily: "var(--font-i_r)",
  fontSize: smallButton
    ? "0.8em"
    : {
        xl: "1.2em",
        lg: "1.2em",
        md: "1em",
      },
  fontWeight: "600",
  lineHeight: "2.4em",
  letterSpacing: "0.013em",
  textAlign: "center",
  textTransform: "capitalize",
  minWidth: smallButton ? "8em" : "12em",
  ...(originHeight ? {} : { minHeight: smallButton ? "2em" : "4em" }),
  zIndex: 2,
  "&:hover": {
    backgroundColor: "rgb(0, 0, 0)",
  },
  ...(sx?.fullWidth && {
    width: "100%",
  }),
}));

function ConnectWallet({
  label,
  onClick,
  sx,
  isHeader,
  fullWidth,
  originHeight,
  smallButton,
}) {
  return (
    <StyledButton
      variant="outlined"
      onClick={() => onClick()}
      sx={{ ...sx, fullWidth }}
      className={isHeader ? "header-animation" : ""}
      originHeight={originHeight}
      smallButton={smallButton}
      size={smallButton ? "small" : undefined}
    >
      {label}
    </StyledButton>
  );
}

export default ConnectWallet;
